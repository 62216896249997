@use "./styles/main";

@import "react-toastify/dist/ReactToastify.css";

// Import Tailwind after @use rules
@import "./styles/tailwind";

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

// Use the font in your styles
body {
  font-family: 'Inter', sans-serif;
}
